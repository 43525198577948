import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Supplier Audit",
  "description": "The supplier audit is to evaluate the performance of potential/existing suppliers and an annual review of the quality system",
  "author": "Happy Fei",
  "categories": ["qc-testing"],
  "date": "2022-07-01T00:00:00.000Z",
  "featured": false
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The supplier audit is to evaluate the performance of potential/existing suppliers and an annual review of the quality system, which is a key part of the supplier management process. It is aimed at suppliers with any development potential preliminarily screened based on supply market investigation and research.`}</p>
    <p>{`References: QA Director, Supplier Quality Management GB/T 19001 Standard, Baidu Baike, etc.`}</p>
    <h2 {...{
      "id": "purpose-of-supplier-audit"
    }}>{`Purpose of Supplier Audit`}</h2>
    <p>{`According to the different material classifications and mastery levels, the supplier audit can be conducted before, during and after supplier certification. The purpose is to identify and screen out the best suppliers, optimize the supplier structure, and improve the competitive edge.`}</p>
    <p>{`The supplier audit will help improve our understanding of suppliers, maintain product performance, processing technology and details of supplier management, and identify any risk in the supplier's production procedure.`}</p>
    <h2 {...{
      "id": "supplier-audit-method"
    }}>{`Supplier Audit Method`}</h2>
    <p>{`The supplier audit follows two main methods: subjective judgment and objective judgment. Subjective judgment is to evaluate suppliers based on personal impressions and experience. It lacks scientific standards based on general and vague judgment evidence. While objective judgment is to evaluate and audit suppliers quantitatively based on pre-established standards or guidelines. It is a methodology includes the survey method, on-site scoring method, supplier performance evaluation, comprehensive supplier audit, and total cost method.`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Survey method. For the survey method, a standard questionnaire form will be sent to suppliers in advance who will fill in the form and send it back for comparison. It is frequently used in scenarios such as bidding, inquiry and preliminary profile of a supplier.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`On-site scoring method. For the on-site scoring method, some formatted questions will be prepared beforehand for professionals from different departments of an organization who will be sent to a supplier's site to audit and confirm.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Supplier performance evaluation. Supplier performance evaluation will track, evaluate and rate an existing supplier on their order supply, quality and price.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Comprehensive supplier audit. The comprehensive supplier audit is to perform an evaluation on the organizational level of a supplier, engaged by professional staff in quality, engineering, planning, procurement and other departments. Generally, it combines questionnaires and on-site audits.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Total cost method. The total cost method is to reduce the overall cost of a supplier to a new bottom line, thereby reducing the purchase price. As a method requiring suppliers to fully cooperate during the procedure, the buyer organizes a competent and comprehensive expert team to analyze the supplier's finance and cost in detail, recognizing how to reduce costs for suppliers to implement and improve, with the benefit of improvement being shared by both parties.`}</p>
      </li>
    </ol>
    <p>{`The cost of audit depends on the method and scale: charges of the product audit is mainly for product measurement and verification; the process audit and system audit are generally performed on the site of a supplier, and the main costs include auditors' audit hours and travel expenses. A third-party audit might also be applied but with higher costs.`}</p>
    <p>{`As to when the audit might complete, it depends on the audit scale. Generally, the product audit takes much time, requiring a verification of the supplier’s product on specifications such as the size, function, appearance, and reliability. Further confirmation from customers might be applied in certain cases. The duration of the process audit and system audit depends on the size of the supplier’s plant and audit scale, generally in 1 to 2 days.`}</p>
    <h2 {...{
      "id": "supplier-audit-coverage"
    }}>{`Supplier Audit Coverage`}</h2>
    <p>{`Due to the differences in supplier conditions (different advantages and disadvantages), objective scoring items are needed as the basis to select qualified suppliers. Therefore, the audit shall cover detailed items, including the following.`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Business Conditions It mainly includes the operation history of a supplier, the qualifications of the person in charge, the amount of registered capital, the number of employees, the completion record and performance, the main customers, and the financial condition.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Production Capacity. It mainly evaluates whether the supplier's production equipment is advanced, the production capacity has reached its full potential, the space distance between production buildings, and the manpower adequacy for production operation.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Technical Capability. It evaluates mainly on whether a technology used by a supplier is self-developed or imported, whether a supplier cooperates with internationally renowned technology development institutions, technical assessment of existing or trial products, product development cycle, number of technical personnel and education level, etc.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Management System. It evaluates mainly on whether a production process is streamlined and reasonable, the output efficiency, whether the material control procedure is computerized, whether a supplier changed its production plan frequently, and whether the procurement operation serves as a good basis for cost calculation.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Quality Management. It evaluates mainly on the implementation of quality management guidelines, policies, and quality management system, whether the supplier has a manual of quality management system, an operation plan of quality assurance, an annual target of quality inspection, an evaluation level by a political organization, and ISO9000 certification.`}</p>
      </li>
    </ol>
    <h2 {...{
      "id": "supplier-audit-process"
    }}>{`Supplier Audit Process`}</h2>
    <p>{`The supplier audit is performed based on research and analysis of the supply market. To investigate the supply market, the premise is to collect information and materials of suppliers. Only with detailed information, can an objective and fair audit of a supplier be made. During the market research, the information of a supplier's condition shall be collected in terms of its market distribution, the quality and price of purchase items, and its production scale.`}</p>
    <ol>
      <li parentName="ol">{`Identify the main indicators for the supplier audit
The audit indicators vary from supplier to supplier. Therefore, specific indicators shall be identified for the suppliers to be audited according to their reality and the features of the items purchased by the company.`}</li>
    </ol>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Establish a supplier audit team
For the supplier audit, different audit teams shall be established according to the different items purchased. For certain standard products and those with a relatively low value, an audit team could be established as determined by the discretion of purchasing personnel. It is the simplest, fastest and the most convenient method; for non-standard items and those with a large value, a cross-functional team or a commodity team can be established for auditing tasks. A cross-functional team is an ad hoc supplier audit organization consisting of personnel from the purchasing department, quality department, material management department, engineering and R&D department, supervisors or finance department, depending on the nature of the purchased items.`}</li>
    </ol>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Comprehensive score
Comprehensive score is the final step in the supplier audit. The audit team will weigh each audit item for the significance to each other and score respectively. After the audit items and weight of the supply is determined, a supplier rating form will be established according to the feedback questionnaire from the supplier and on-the-spot investigation result.`}</li>
    </ol>
    <p>{`During the supplier audit, NexPCB will perform two assessments: general project assessment and on-site assessment accordingly, as shown in the following figure:`}</p>
    <h3 {...{
      "id": "flowchart-of-supplier-assessment"
    }}>{`Flowchart of Supplier Assessment`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "788px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "108.1081081081081%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAWCAYAAADAQbwGAAAACXBIWXMAABcRAAAXEQHKJvM/AAACuklEQVQ4y3WVCZOqQAyE+f+/TctVEC9AEW8FPBC8s/XFjc+35U7VFMwM6XSnAziXy0UOh4PO/X4v5/NZ7ve7XK9XnbfbTU6nk6Rp+jpjz86Z7D0eD50ODy0WC1mtVrJcLhWYvePxKGVZ6pqzyWQim81GsiyTPMv0zCaANpyqqmQ+n8t6tZbZbKZs3gfroihkuVjqc9wTA0sGrCCAUq7ObreTVqulYDAlgAFDJgxhBiDnxpJ92MHe931xW66EYSiOfBgAIb84FC/pVVlpMpMHM+7zPBdIkYgkDpvIQgaB0CZrkiQ6p9OpjMdjvScJzxHDRCZGvg9nu91KvVaXIAik3+tLlqaaFRD2XNdVSZQE8PV6rQaNRiOJoqFOzgBXQGyHshWZA9YwILvneVobW6MApoADhlHU1uKdy/miPUYNkIok1qM4lm63q4FxHCtjnmHAkCRDZRjp+gVIcVlYc3If/4D1+32ViGQ6AWbUeTgEKFS3SZSlmca+AH9P3+9Ir9vTQOR0Oh0ZDAbKHBX1el0TUn8YooLSKeCntiEQGb3eP1CYcE8whmAEJcB9zl6SKXL64yxXcwt57XZbawUI5jQaDWWI+yTBPFgCbP3plGWlmWHEFSehj8Svr6YEg0CTWbtwJRm9u9vuJAojrTkvwZ+SoY9caheGkbYGCTAHUIyh1jCzDuCN+s8Ucxl2zx6LVGIQhApGEHWDIbJRwqsIOOc4/idD6kPLdPynu0i2omMI9WTfPm/JONH66/cQRvZloanJBDsmhtAegGAeAxNgCBCseQ6TPNfTtYOrgOAYk0xkr9VqeqWO1NPcfx/2AvAc7/zHzxcskWx9ZvWDETXjSmLkwY7WImGz2XwC/n5LGJQBadSIYAyAqet6yggQysCZ/hLyXJPrF9uYGZgB2yfdfkQEEUwiriR5/1lZY38D+m+DExd3O3UAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/178802c7d567c5b80c46b5d2f139c12f/652f5/flowchart-supplier-assessment.webp 259w", "/static/178802c7d567c5b80c46b5d2f139c12f/c29d2/flowchart-supplier-assessment.webp 518w", "/static/178802c7d567c5b80c46b5d2f139c12f/4d911/flowchart-supplier-assessment.webp 788w"],
            "sizes": "(max-width: 788px) 100vw, 788px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/178802c7d567c5b80c46b5d2f139c12f/a2ead/flowchart-supplier-assessment.png 259w", "/static/178802c7d567c5b80c46b5d2f139c12f/6b9fd/flowchart-supplier-assessment.png 518w", "/static/178802c7d567c5b80c46b5d2f139c12f/ea7fb/flowchart-supplier-assessment.png 788w"],
            "sizes": "(max-width: 788px) 100vw, 788px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/178802c7d567c5b80c46b5d2f139c12f/ea7fb/flowchart-supplier-assessment.png",
            "alt": "Supplier Assessment Flowchart",
            "title": "Supplier Assessment Flowchart",
            "loading": "eager",
            "decoding": "async",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></p>
    <p>{`Supplier audit levels:
For the control of procurement and supply, the supplier audit can be limited to the product audit, process audit, system audit, up to the audit of the supplier's overall management system.`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Product Audit`}</p>
        <p parentName="li">{`The product audit will evaluate product comprehensively and systematically from the perspective of customers. The effectiveness of production will be evaluated and verified based on how a product meets the specific functional requirements and quality requirements. With frequent product audits, potential risks in product delivery to customers can be identified in a timely manner, reducing quality losses from product flaws delivered to customers.`}</p>
        <p parentName="li">{`Suppliers shall establish a management system for the product audit, assigning persons in charge, plan and implement product audit accordingly. Product auditors shall be qualified with required certifications. Suppliers shall determine audit intervals based on the product specifications, the importance of product functions, the stability of process, and customer requirements. The planning of product audits includes clarifying audit objects, collecting audit criteria, compiling audit checklist, determining product sampling plans and inspection plans, and specifying product evaluation methods.`}</p>
        <p parentName="li">{`Generally, the product audit shall be performed by the following timeline:`}</p>
        <ul parentName="li">
          <li parentName="ul">{`New product development tooling samples, trial production process samples;`}</li>
          <li parentName="ul">{`Annual report of type test for batch products;`}</li>
          <li parentName="ul">{`After quality problems or quality improvement;`}</li>
          <li parentName="ul">{`After changes of products and processes;`}</li>
          <li parentName="ul">{`Regular monitoring and evaluation of product and process stability.`}</li>
        </ul>
        <p parentName="li">{`The product audit is aimed at delivered products from an independent production process or production line, which might come from external or internal customers.`}</p>
        <p parentName="li">{`The standard operation of product audits shall be planned according to customer's requirements for the function and performance of products. The general product audit includes size, material, function, reliability, packaging, and labeling. For certain high-cost functional/performance testing items for durability, reliability, and destructive tests, the QFD method can be combined to review the product features directly related to this functions/performance to reduce the audit cost and cycle.`}</p>
        <p parentName="li">{`Prepare an audit checklist according to the product audit guideline.
The results of product audits shall be recorded, maintained, and evaluated. Evaluate the defects found in the product audit and determine the defect level in three classes: A, B, and C.`}</p>
        <p parentName="li">{`For Class A defects, the whole batch shall be traced, an effective emergency solution shall be in place to prevent defected products from being used, while necessary corrective measures shall be taken timely;`}</p>
        <p parentName="li">{`For Class B defects, a team with members from quality and technical departments shall be organized for the audit. Formulate effective control through tests to verify and evaluate quality risks, decide if a trace back is necessary according to the quality risk conclusion.`}</p>
        <p parentName="li">{`The reasons of all defects shall be analyzed thoroughly with corrective and preventive actions. The original analysis and corrective actions shall be expanded to the level of control and management.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Process audit`}</p>
        <p parentName="li">{`The process audit is to evaluate the production process and production service of the product line, how complete and rational is the execution plan, the execution of the process, and how stable and accurate are the results. Suppliers shall establish corresponding processes and systems for process audits, identify responsible persons, and plan the process audit. The personnel who perform the audit shall be qualified with corresponding certifications.`}</p>
        <p parentName="li">{`The supplier's internal process audit has a timeline as follows:`}</p>
        <ul parentName="li">
          <li parentName="ul">{`Production audit of new product trial`}</li>
          <li parentName="ul">{`Periodic process audit`}</li>
          <li parentName="ul">{`After changes of products and processes.`}</li>
          <li parentName="ul">{`After a serious problem discovered in product review.`}</li>
        </ul>
        <p parentName="li">{`The supplier shall establish a plan for process audits with an interval based on process stability and the functional importance of the product line. The process audit shall be performed at least annually for important components;`}</p>
        <p parentName="li">{`A more rigorous process audit planning is recommended in accordance with VDA 6.3 requirements. Moreover, the process audit shall be performed with focuses on problem history, customer complaints, and unqualified items.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`System audit`}</p>
        <p parentName="li">{`The system audit is to verify whether the quality system planning is complete, whether the planned requirements of quality management system are followed to find opportunities for improvement in the organization, ensuring the continuous improvement of the organization. The system audit will evaluate whether the quality management system of an organization will implement organizational targets in all aspects during the operation accurately, effectively and rationally.`}</p>
        <p parentName="li">{`The system audit covers the following aspects:`}</p>
        <ul parentName="li">
          <li parentName="ul">{`Whether the existing quality management system have a complete process.`}</li>
          <li parentName="ul">{`Whether the sequence and interaction of the various processes are clear.`}</li>
          <li parentName="ul">{`Whether the methods and criteria for each process are clear.`}</li>
          <li parentName="ul">{`Whether the resources and information input to each process are clear and readily available.`}</li>
          <li parentName="ul">{`Whether the monitoring results of each process are analyzed for continuous improvement.`}</li>
        </ul>
        <p parentName="li">{`Please refer to ISO9001-2015, IATF16949-2016, VDA6.1 and other system requirements for system audit planning.`}</p>
        <p parentName="li">{`Records of the system audit shall be kept, and non-conformities shall be audited and tracked to make an improvement plan.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Management review`}</p>
        <p parentName="li">{`The audit of company management team is the highest level in the pyramid of the supplier audit system. It will not only inspect the quality system of suppliers, but also audit the major enterprises management process such as the supplier's management level, finance, planning and manufacturing systems, information system, and engineering capabilities.`}</p>
        <p parentName="li">{`In reality, buyers will audit general suppliers on the product and process level. For the key product, the focus will be on whether the supplier's quality system construction is complete, whether it is able to ensure quality. Buyers, especially the international companies, might also pay attention to the supplier's system construction in HSE and other aspects, as well as how the operation level to support the management system of the supply chain. Based on our requirements, NexPCB has formulated its own supplier strategy by classifying suppliers into A, B, and C, for which different assessment management requirements are adopted. Please refer to the following table for the overall supplier assessment:`}</p>
      </li>
    </ol>
    <h3 {...{
      "id": "evaluation-form-of-supplier-performance"
    }}>{`Evaluation Form of Supplier Performance`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1035px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "129.34362934362935%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAaCAIAAAA44esqAAAACXBIWXMAAB7CAAAewgFu0HU+AAACYklEQVQ4y61Uy3LjIBDM//9U7s7FSew8LFAJ8wYJgZCMEZK9VaaS3ezKziHbpwGqa+jugbvzD3D35yKl9NdxSmmapnme0wdOp9MXcl4bY/b7/Xa7fXp6enx83Gw26/Wac04IgRBqrY0xjDGt9SflN5lzjhCqqurh4eH+/n61WkEIhRAQws1mQwhhjAEACCHL5NVqVRQFhLAsy9fX1/V6/fz8vNvtAAB5/+XlhVK6TMYYz/P8Kel0wfl8jjGGEA6HgxBi+dqU0qIoQggppRCCc24YhuyWtVYIQSndbrec8wWylLJpGs45pZQQIqU0xgzDEELI++M4dl2nlFogK6UYY7kDIWR/gfd+HEfnnNZ6nucQwvK1lVLGGIwxAAAhpJTSWnvvY4xt2wohvuncNA2lVErZ930ejBjjOI7W2iz1eDwudxZCYIwJIRhjKWVWG2NMKTnnpJTTNPV9v9xZCJF1lmWJMa7r2hgTQhjHsW1bxtg8z4fD4WpUAADG2GfPHG+M0RgjhJimaRiGq5qzZ8cLwgdijMMwWGtvaVZKOecwxvv9HiGEMRZCMMY45865rutSSt77q5qrqoIQ7na7t7c3CCG6gBDStq1zLqV01TClVF3XZVkCAPLbyIAQKqXytNxy2znX9721tm3bnNY0TeMFOTPvvZRy+VVVVWWMaZrGe28v6LrOOZfrbNuyYVrrLBgAkP8NY0zWorV2zsUYb41nVVXZc+99DiwXeU6/mbD39/eiKJRSQgiEUP43cjyEkDwIy5pDCHVdNxdYa7P4pmlyUdc1pRQh5L3/Qv4///bpJv4l/wIj9cm5AiLefQAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/a1e004a6bdb6cc282fe7e6bdc2e19c4d/652f5/evaluation-form-of-supplier-performance.webp 259w", "/static/a1e004a6bdb6cc282fe7e6bdc2e19c4d/c29d2/evaluation-form-of-supplier-performance.webp 518w", "/static/a1e004a6bdb6cc282fe7e6bdc2e19c4d/77d2c/evaluation-form-of-supplier-performance.webp 1035w", "/static/a1e004a6bdb6cc282fe7e6bdc2e19c4d/ba7d2/evaluation-form-of-supplier-performance.webp 1553w", "/static/a1e004a6bdb6cc282fe7e6bdc2e19c4d/e5be3/evaluation-form-of-supplier-performance.webp 1700w"],
            "sizes": "(max-width: 1035px) 100vw, 1035px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/a1e004a6bdb6cc282fe7e6bdc2e19c4d/a2ead/evaluation-form-of-supplier-performance.png 259w", "/static/a1e004a6bdb6cc282fe7e6bdc2e19c4d/6b9fd/evaluation-form-of-supplier-performance.png 518w", "/static/a1e004a6bdb6cc282fe7e6bdc2e19c4d/e3189/evaluation-form-of-supplier-performance.png 1035w", "/static/a1e004a6bdb6cc282fe7e6bdc2e19c4d/44d59/evaluation-form-of-supplier-performance.png 1553w", "/static/a1e004a6bdb6cc282fe7e6bdc2e19c4d/dcb79/evaluation-form-of-supplier-performance.png 1700w"],
            "sizes": "(max-width: 1035px) 100vw, 1035px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/a1e004a6bdb6cc282fe7e6bdc2e19c4d/e3189/evaluation-form-of-supplier-performance.png",
            "alt": "Evaluation Form of Supplier Performance",
            "title": "Evaluation Form of Supplier Performance",
            "loading": "eager",
            "decoding": "async",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></p>
    <h2 {...{
      "id": "supplier-management-risks"
    }}>{`Supplier Management Risks`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Depending on the auditors' limited knowledge of the supplier's products, they may not be able to identify risks of the supplier's production, weak points in their management, etc. In addition, they might present some unprofessional opinions to the supplier based on the business relations, thus increasing the management cost of the supplier.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Due to the long audit interval, the audit evaluation of suppliers, if not objective, might bring risks to the supplier's products;`}</p>
      </li>
    </ol>
    <p>{`The supplier audit is a method to improve suppliers’ quality capabilities, stabilize supplier relationships, and reduce procurement risks and costs. Effective supplier management is always a focus in a company's quality management. For our long-term suppliers, we always pursue the goal of caring customers and stakeholders, and improve product quality to objectively and fairly evaluate whether suppliers can provide qualified products in a consistent and stable manner, the supplier audit is needed to drive suppliers in their effort of quality management, ensuring that the products sold by the company will continue to meet the customer requirements.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      